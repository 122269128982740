var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-dropdown',{staticClass:"float-right",attrs:{"right":"","toggle-class":"arrow-none card-drop","variant":"white"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"mdi mdi-dots-vertical"})]},proxy:true}])},[_c('b-dropdown-item',[_vm._v("Sales Report")]),_c('b-dropdown-item',[_vm._v("Export Report")]),_c('b-dropdown-item',[_vm._v("Profit")]),_c('b-dropdown-item',[_vm._v("Action")])],1),_c('h4',{staticClass:"card-title mb-3"},[_vm._v("Sources")]),_c('div',[_vm._m(0),_c('div',{staticClass:"table-responsive mt-4"},[_c('table',{staticClass:"table table-hover mb-0 table-centered table-nowrap"},[_c('tbody',[_c('tr',[_vm._m(1),_vm._m(2),_c('td',[_c('apexchart',{staticClass:"apex-charts",attrs:{"height":"35","dir":"ltr","series":_vm.source1.series,"options":_vm.source1.chartOptions,"type":"line","width":"80"}})],1),_vm._m(3)]),_c('tr',[_vm._m(4),_vm._m(5),_c('td',[_c('apexchart',{staticClass:"apex-charts",attrs:{"height":"35","dir":"ltr","series":_vm.source2.series,"options":_vm.source2.chartOptions,"type":"line","width":"80"}})],1),_vm._m(6)]),_c('tr',[_vm._m(7),_vm._m(8),_c('td',[_c('apexchart',{staticClass:"apex-charts",attrs:{"height":"35","dir":"ltr","series":_vm.source3.series,"options":_vm.source3.chartOptions,"type":"line","width":"80"}})],1),_vm._m(9)])])])]),_vm._m(10)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mb-2"},[_vm._v("Total sources")]),_c('h4',[_vm._v("$ 7652")]),_c('div',{staticClass:"text-success"},[_c('i',{staticClass:"mdi mdi-menu-up font-size-14"}),_vm._v("2.2 % ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"width":"60px"}},[_c('div',{staticClass:"avatar-xs"},[_c('div',{staticClass:"avatar-title rounded-circle bg-light"},[_c('img',{attrs:{"src":require("@/assets/images/companies/img-1.png"),"alt":"","height":"20"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('h5',{staticClass:"font-size-14 mb-0"},[_vm._v("Source 1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('p',{staticClass:"text-muted mb-0"},[_vm._v("$ 2478")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"avatar-xs"},[_c('div',{staticClass:"avatar-title rounded-circle bg-light"},[_c('img',{attrs:{"src":require("@/assets/images/companies/img-2.png"),"alt":"","height":"20"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('h5',{staticClass:"font-size-14 mb-0"},[_vm._v("Source 2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('p',{staticClass:"text-muted mb-0"},[_vm._v("$ 2625")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"avatar-xs"},[_c('div',{staticClass:"avatar-title rounded-circle bg-light"},[_c('img',{attrs:{"src":require("@/assets/images/companies/img-3.png"),"alt":"","height":"20"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('h5',{staticClass:"font-size-14 mb-0"},[_vm._v("Source 3")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('p',{staticClass:"text-muted mb-0"},[_vm._v("$ 2856")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-4"},[_c('a',{staticClass:"btn btn-primary btn-sm",attrs:{"href":"#"}},[_vm._v("View more")])])
}]

export { render, staticRenderFns }